<template>
  <div class="card has-dark-background">
    <div class="card-content has-text-centered">
      <div class="is-flex is-justify-content-center">
        <figure class="image is-128x128">
          <img
            :src="require(`@/assets/img/${iconPath}`)"
            alt="Brak obrazka"
          />
        </figure>
      </div>

      <div>
        <h3 class="title is-5">{{ title }}</h3>
        <h4 class="subtitle is-6">{{ subtitle }}</h4>
      </div>
      <div class="px-5">
          <div class="mt-4">
                <b-button type="is-primary" @click="changeView(loginViewName)" rounded expanded>ZALOGUJ SIĘ</b-button>
            </div>
            <div class="mt-4">
                <b-button type="is-dark" @click="changeView(registrationViewName)" rounded expanded>ZAREJESTRUJ SIĘ</b-button>
            </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    props: {
        iconPath: String,
        title: String,
        subtitle: String,
        loginViewName: String,
        registrationViewName: String
    },

    methods: {
        changeView(viewName) {
            this.$router.push({
                name: viewName
            })
        },
    }
};
</script>

<style lang="scss" scoped>
.card.has-dark-background {
    background-color: rgba(255, 255, 255, 0.7)
}
</style>