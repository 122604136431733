<template>
  <div>
    <section class="has-main-background pb-6 has-min-height">
      <header class="px-6">
        <div class="level px-4">
          <div class="level-left">
            <div class="level-item" @click="$router.go({ name: 'Home' })">
              <b-image :src="require('@/assets/img/home/logo.png')"></b-image>
            </div>
          </div>
          <div class="level-right">
            <div class="level-item">
              <h2 class="title has-text-primary">
                ZALOGUJ SIĘ / ZAREJESTRUJ SIĘ
              </h2>
            </div>
          </div>
        </div>

        <hr />
      </header>
      <section class="section has-height-100 is-flex is-align-items-center">
        <div class="container">
          <div class="columns">
            <div class="column is-1"></div>
            <div class="column is-4">
              <main-auth-card
                iconPath="home/icons/business_account.png"
                loginViewName="CompanyLogin"
                registrationViewName="CompanyRegistration"
                title="KONTO BIZNESOWE"
                subtitle="wirtualne biuro"
              ></main-auth-card>
            </div>
            <div class="column is-2"></div>
            <div class="column is-4">
              <main-auth-card
                iconPath="home/icons/client.png"
                loginViewName="ClientLogin"
                registrationViewName="ClientRegistration"
                title="KONTO KLIENTA"
                subtitle="wirtualny garaż"
              ></main-auth-card>
            </div>
            <div class="column is-1"></div>
          </div>
        </div>
      </section>
    </section>
    <page-footer></page-footer>
  </div>
</template>

<script>
import MainAuthCard from "@/components/auth/MainAuthCard";
import Footer from "@/components/common/Footer";

export default {
  components: {
    "main-auth-card": MainAuthCard,
    "page-footer": Footer,
  },
};
</script>

<style lang="scss">
.has-main-background {
  background-image: url("../assets/img/auth/main_background.jpg");
  background-size: cover;
}

.has-min-height {
  min-height: 90vh;
}

.has-height-100 {
  height: 70vh;
}
</style>